import {
  MAC,
  ANC,
  AC,
  AP,
  BS,
  BN,
  BA,
  DB,
  OB,
  WB,
  CO,
  BL,
  BNS,
  BSC,
  LB,
  ROW,
  ICON,
} from 'CORE__UI/apps/MyAccountApp/core__myAccount';
import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

const MAC_J = styled(MAC)`
  background-color: ${GREYS.blueM};
`;

const BS_J = styled(BS)`
  color: ${GREYS.white};
`;

const BN_J = styled(BN)`
  color: ${GREYS.white};
`;

const DB__J = styled(DB)`
  background-color: ${BRAND.secondary};
  color: ${GREYS.white};
  border-color: transparent;
  border-radius: 24px;
  margin-top: 16px;
  text-transform: uppercase;
`;

const WB_J = styled(WB)`
  color: ${GREYS.white};
  border-color: ${GREYS.silver};
  text-transform: uppercase;
  border: 2px solid ${GREYS.white};
  border-radius: 25px;
`;

const CO_J = styled(CO)`
  color: ${GREYS.white};
  background-color: ${GREYS.silverD};
  border-color: ${GREYS.silver};
  text-transform: uppercase;
`;

const BNS_J = styled(BNS)`
  color: ${GREYS.white};
`;

const BL_J = styled(BL)`
  background: ${GREYS.silver};
`;

const LB_J = styled(LB)`
  color: ${GREYS.white};
`;
const BA_J = styled(BA)`
  color: ${GREYS.white};
`;
const ROW_J = styled(ROW)`
  display: flex;
  align-items: self-end;
  gap: 10px;
`;
const ICON_J = styled(ICON)`
  display: block;
  height: 22px;
  width: 22px;
`;

export {
  MAC_J as MAC,
  ANC,
  AC,
  AP,
  BS_J as BS,
  BN_J as BN,
  BA_J as BA,
  DB__J as DB,
  OB,
  WB_J as WB,
  CO_J as CO,
  BL_J as BL,
  BNS_J as BNS,
  BSC,
  LB_J as LB,
  ROW_J as ROW,
  ICON_J as ICON,
};
