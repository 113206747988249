import {
  BRB,
  BRBT,
  BRBBC,
  BRS,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__remainingBoosts';
import styled from 'styled-components';
import { BRAND, GREYS } from '../../../globals/colours';

const BRBJN = styled(BRB)`
  background: ${BRAND.secondary};
  border: none;
`;

const BRBTJN = styled(BRBT)`
  color: ${GREYS.black};
`;

const BRSJN = styled(BRS)`
  color: ${GREYS.black};
`;

export { BRBJN as BRB, BRBTJN as BRBT, BRBBC, BRSJN as BRS };
