import React, { useEffect, useRef, useState } from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { MyAccount } from './components/MyAccount';
import PropTypes from 'prop-types';
import FSBCustomerSession from '../../../../services/session/models/core__session.models.fsb';
import { PC } from 'UI/apps/MyAccountApp/MyAccountReact';
import { getCookie } from 'Services/cookie/core__cookies';
import { decode } from '@msgpack/msgpack';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';
import { removeCookie } from 'Services/cookie/core__cookies';
import { getCustomerCard } from '../core__my-account.store';
import { useDispatch } from 'react-redux';
/**
 * this component contains the category list and sub categories list
 * @param {*} setCustomerData function to set the customer detail from the session
 */
export const defaultSection = item => {
  setterGetterFromLocalstorage({
    keyName: 'tabname',
    keyValue: item,
    action: 'set',
  });
};
const MyAccountReact = ({
  setCustomerData,
  updateAccountBalance,
  updateCasinoBalance,
  appConfig,
}) => {
  const dispatch = useDispatch();
  const showSelfImposingMeasures = appConfig?.olympic;
  const accountOddsBoost = appConfig?.accountodds;
  const showCreditLimit = appConfig?.showcreditlimit;
  const showChatButton = appConfig?.showchatbutton;
  //Initialising initial state for opening my account popup
  const [openMyAccount, setOpenMyAccount] = useState(false);
  const [render, setRender] = useState(false);
  // Initialising ref value as null
  const wrapperRef = useRef(null);

  useEffect(() => {
    setRender(true);
    //Pubsub event listner when ever the my account button is being clicked
    const openMyAccountListener = PubSub.listen(
      PubsubEvents.openMyAccount,
      ({ starIconUrl }) => {
        //Getting logged in session data
        const data = FSBCustomerSession.getSession();

        //setting the my account popup state to true
        setOpenMyAccount(true);
        //function to set the customer detail from the session
        setCustomerData({ ...data, starIconUrl });
      }
    );
    const unsettledBetCountListener = PubSub.listen(
      PubsubEvents.UpdateBalanceWithData,
      ({ customer }) => {
        const data = FSBCustomerSession.updateSession(customer);
        const { unsettledBetsCount } = customer || {};
        setCustomerData({ ...data, unsettledBetsCount });
      }
    );
    // when Post betslip success , then update wallet balance
    const updateWalletListener = PubSub.listen(
      PubsubEvents.UPDATE_WALLET,
      () => {
        PubSub.emit('session:update');
      }
    );

    const sessionToken = getCookie('AppSession');

    // Pubsub emit and listen to get balance update
    PubSub.emit('ws:sub:balance', sessionToken);
    const wsBalanceListener = PubSub.listen('ws:balance', wsData => {
      if (wsData?.encodedData) {
        const getArray = new Uint8Array(wsData.encodedData);
        const data = decode(getArray);
        if (data?.cashBalance) {
          updateAccountBalance(Number(data?.cashBalance));
        }
        let baseUrl = '';
        if (typeof window !== 'undefined') {
          baseUrl = window.location.href;
        }
        if (baseUrl.indexOf('casino') > -1 && data?.bonusBalance) {
          updateCasinoBalance(Number(data?.bonusBalance));
        }
      }
    });

    return () => {
      openMyAccountListener?.unsubscribe();
      updateWalletListener?.unsubscribe();
      wsBalanceListener?.unsubscribe();
      unsettledBetCountListener.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeMyAccountPopUp = () => {
    //setting the my account popup state to false
    PubSub.emit(PubsubEvents.ICON_ROTATE, { open: false });
    setOpenMyAccount(false);
  };

  useEffect(() => {
    //function for checking the click event when ever click outside the my account popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMyAccountPopUp();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  //function for logout session of the customer
  const logout = () => {
    setOpenMyAccount(false);
    removeCookie('loginPopupAcknowledged');
    removeCookie('pepStatePopUp');
    removeCookie('realityCheckAckTime');
    removeCookie('bsource');
    removeCookie('downloadPopUpAppViewed');
    PubSub.emit(PubsubEvents.SESSION_DESTROY);
    FSBCustomerSession.deleteSession();
    window.location.href = showSelfImposingMeasures
      ? '/casino/'
      : '/sportsbook/';
  };

  useEffect(() => {
    const listener = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const sessionToken = getCookie('AppSession');
      dispatch(getCustomerCard(sessionToken));
    });
    const sessionToken = getCookie('AppSession');
    dispatch(getCustomerCard(sessionToken));
    return () => {
      listener.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    (render && (
      <PC active={openMyAccount}>
        <div ref={wrapperRef}>
          {openMyAccount && (
            <MyAccount
              casinoTheme={appConfig?.casinotheme}
              accountOddsBoost={accountOddsBoost}
              hiddenTabs={appConfig?.hiddentabs || ''}
              openQuickDeposit={appConfig?.openquickdeposit || ''}
              kycCheck={appConfig?.kyccheck}
              showSelfImposingMeasures={showSelfImposingMeasures}
              logout={logout}
              defaultSection={defaultSection}
              customRoutes={appConfig?.olympic}
              termsAndGamblingTabs={appConfig?.termsandgamblingtabs}
              showUnsettledBetCount={appConfig.showunsettledbetcount}
              enableArrow={appConfig?.enablearrow}
              showCreditLimit={showCreditLimit}
              showChatButton={showChatButton}
              closeMyAccountPopUp={closeMyAccountPopUp}
              redirectKYCurl={appConfig?.redirectkycurl}
              hideActivateAccountOption={appConfig?.hideactivateaccountoption}
              showWithdrawableBalance={appConfig?.showwithdrawablebalance}
              directRoutes={appConfig?.directroutes}
              standaloneCustomerBalance={appConfig?.standalonecustomerbalance}
            />
          )}
        </div>
      </PC>
    )) ||
    ''
  );
};

MyAccountReact.propTypes = {
  setCustomerData: PropTypes.func,
  updateAccountBalance: PropTypes.func,
  updateCasinoBalance: PropTypes.func,
  appConfig: PropTypes.object,
  casinoTheme: PropTypes.bool,
  termsAndGamblingTabs: PropTypes.bool,
};

export default MyAccountReact;
